import React from 'react';
import Clipboard from 'react-clipboard.js';
import { ShareButton, ShareBlock } from 'react-custom-share';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/mail-outline.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/chain.svg';
import { ReactComponent as PrintIcon } from '../../assets/icons/print.svg';
import { ReactComponent as BlueskyIcon } from '../../assets/icons/bluesky.svg';
import { MetaInfo } from '../typography';
import classnames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
   `
	);
	return site.siteMetadata;
}


const YoastShareButton = (props) => {
	let forwarded_props = { ...props }
	if (props.network === 'Twitter') {
		forwarded_props.text = props?.twitter_title ?? props.text;
		forwarded_props.longtext = props?.twitter_description ?? props.longtext;
	}
	if (props.network === 'Facebook') {
		forwarded_props.text = props?.fb_title ?? props.text;
		forwarded_props.longtext = props?.fb_description ?? props.longtext;

	}
	return (
		<ShareButton {...forwarded_props} />
	);
};
class CopyLink extends React.Component {
	constructor(props) {
		super(props);
		this.defaultText = 'copy link';
		this.copiedText = 'copied!';
		this.state = {
			copyText: this.defaultText
		};
	}

	render() {
		return (
			<Clipboard component="button"
				className="flex hover:text-brand hover:pointer mx-3 items-center"
				data-clipboard-text={this.props.value}
				onSuccess={e => this.setState({ copyText: this.copiedText })}
			>
				<LinkIcon className="mr-2" />
				<MetaInfo>{this.state.copyText}</MetaInfo>
			</Clipboard>
		);
	}
}

const parseYoastMetas = (yoast_string) => {

	if (typeof DOMParser !== 'undefined') {
		const parser = new DOMParser()
		const parsed = parser.parseFromString(yoast_string, 'text/html')
		let props = {
			text: parsed.title,
			longtext: parsed.querySelector('meta[name="description"]')?.content
		}
		let social_overrides = {
			fb_title: parsed.querySelector('meta[property="og:title"]')?.content ?? props.text,
			fb_description: parsed.querySelector('meta[property="og:description"]')?.content ?? props.longtext,
			twitter_title: parsed.querySelector('meta[name="twitter:title"]')?.content ?? props.text,
			twitter_description: parsed.querySelector('meta[name="twitter:title"]')?.content ?? props.longtext,
		}
		return {
			...props,
			...social_overrides
		}
	}
	return {};
}
const PrintButton = (props) => {
	return (
		<div className="mx-3 cursor-pointer flex justify-center">
			<PrintIcon onClick={() => { window.window.print(); }} />
		</div>
	);
};
export const CeficShare = (props) => {
	const metadata = useSiteMetadata();
	const abs_url = `${metadata.siteUrl}${props.url}`;
	const shareBlockProps = {
		url: abs_url,
		button: YoastShareButton,
		buttons: [
			{ network: "Bluesky", icon: BlueskyIcon, link: `https://bsky.app/intent/compose?text=${abs_url}` },
			{ network: 'Twitter', icon: TwitterIcon },
			{ network: 'Linkedin', icon: LinkedInIcon, },
			{ network: 'Email', icon: EmailIcon }
		],
		buttonClassName: "mx-3 flex justify-center",
		text: '',
		longtext: '',
		...parseYoastMetas(props.context)
	};
	const className = classnames(
		'flex',
		'items-center',
		props.className
	);
	return (
		<div className={className}>
			<MetaInfo className="uppercase mr-3">SHARE</MetaInfo>
			<ShareBlock className="flex flex-no-wrap" {...shareBlockProps} />
			{props?.printOptions?.display &&
				<PrintButton />
			}
			<CopyLink value={abs_url} />

		</div >
	);
};
