import React from 'react';
import classnames from 'classnames';
import {
	StaticQuery,
	graphql
} from 'gatsby';
import {
	ReactComponent as YoutubeIcon
} from '../../assets/icons/youtube.svg';
import {
	ReactComponent as TwitterIcon
} from '../../assets/icons/twitter.svg';
import {
	ReactComponent as LinkedinIcon
} from '../../assets/icons/linkedin.svg';
import {
	ReactComponent as BlueskyIcon

} from '../../assets/icons/bluesky.svg';
import {
	Link
} from '../link';

const SocialLinksComponent = (props) => {

	const iconClassName = classnames([
		props.hoverColor ? "hover:text-" + props.hoverColor :
			'hover:text-brand',
		props.textColor ? "text-" + props.textColor : 'text-grey-darker',
		'fill-current'
	]);

	return (
		<div>
			{props.links.map((l, index) => {
				return (
					<Link url={l.url} key={index} className={"leading-none mx-2 " + (props.fontSize ? props.fontSize : "text-3xl")}>
						{l.network === 'youtube' && <YoutubeIcon className={iconClassName} />}
						{l.network === 'linkedin' && <LinkedinIcon className={iconClassName} />}
						{l.network === 'twitter' && <TwitterIcon className={iconClassName} />}
						{l.network === 'bluesky' && <BlueskyIcon className={iconClassName} />}
					</Link>
				);
			})}
		</div>
	);
};

const SocialLinks = (props) => (
	<StaticQuery query={
		graphql`
      query{
  wordpressAcfOptions {
    options {
      social_networks {
        network
        url
      }
    }
  }
}`
	} render={data => (
		<SocialLinksComponent {...props} links={data.wordpressAcfOptions.options.social_networks} />
	)
	}
	/>
);
export default SocialLinks;
